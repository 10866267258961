<template>
  <div class="Blog">
    <!-- **********inicia intro news********** -->
    <v-container fluid class="py-0">
      <v-row class="row-news d-none d-sm-flex">
        <v-col cols="12" class="position-relative pa-0">
          <v-carousel
            v-model="carouselNews"
            v-bind:height="heightCarouselNews"
            v-bind:show-arrows="false"
            hide-delimiters
            touchless
            interval="3000"
            cycle
          >
            <v-carousel-item v-for="(newsImg, ni) in news" v-bind:key="ni">
              <template v-if="newsImg.cover != null">
                <router-link
                  class="not-text-decoration Blog__row--a"
                  :to="'/post/' + newsImg.url"
                >
                  <v-img
                    class=""
                    :height="heightCarouselNews"
                    :src="
                      'https://www.cymcms.actstudio.xyz/' + newsImg.cover.path
                    "
                  ></v-img>
                </router-link>
              </template>
            </v-carousel-item>
          </v-carousel>

          <v-carousel
            v-model="carouselNewsText"
            v-bind:height="heightCarouselNewsT"
            v-bind:show-arrows="false"
            hide-delimiters
            interval="3000"
            cycle
            class="row-news__blue-div d-none d-sm-flex"
          >
            <v-carousel-item v-for="(newsImg, ni) in news" v-bind:key="ni">
              <router-link
                class="not-text-decoration Blog__row--a"
                :to="'/post/' + newsImg.url"
              >
                <v-card color="#143748" width="100%" tile elevation="0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    :style="'height:' + heightCarouselNewsT + 'px;'"
                  >
                    <v-col cols="auto" class="px-8 px-md-12">
                      <div class="row-news__blue-div__description--news">
                        <span
                          class="bree-serif titles-big-x color-cream line-height-some-titles"
                        >
                          {{ newsImg.title }}
                        </span>
                        <p
                          class="white--text mt-sm-1 mt-md-6 mb-0 continuos-texts-small"
                        >
                          {{ newsImg.description }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </router-link>
            </v-carousel-item>

            <!-- arrows -->
            <div
              class="arrow-right in-home-news d-none d-sm-block"
              @click="carouselNewsText++, carouselNews++"
            >
              <v-img
                src="@/assets/icons/arrow-slider.svg"
                class="arrow-right-img"
              ></v-img>
            </div>
            <!-- /arrows -->
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <!-- **********/termina intro news********** -->

    <!-- **********inicia news********** -->
    <v-container class="pt-16 pt-sm-3">
      <v-row
        class="Blog__row my-sm-12"
        v-for="(newItem, ns) in news"
        v-bind:key="ns"
        v-bind:class="{ changeBackground: ns % 2 == 1 }"
        v-bind:style="
          ns % 2 == 1 ? 'flex-direction:row-reverse !important;' : ''
        "
        no-gutters
      >
        <v-col cols="12" sm="5">
          <template v-if="newItem.miniature != null">
            <router-link
              class="not-text-decoration Blog__row--a"
              :to="'/post/' + newItem.url"
            >
              <v-img
                :src="
                  'https://www.cymcms.actstudio.xyz/' + newItem.miniature.path
                "
                height="100%"
              ></v-img>
            </router-link>
          </template>
        </v-col>
        <v-col cols="12" sm="7">
          <v-row justify="center" class="fill-height" align="center" no-gutters>
            <v-col cols="auto">
              <router-link
                class="not-text-decoration Blog__row--a"
                :to="'/post/' + newItem.url"
              >
                <div class="Blog__row--div-max-width">
                  <span
                    class="Blog__row--span line-height-some-titles montserrat-bold"
                    :class="{ changeColor: ns % 2 == 1 }"
                  >
                    {{ newItem.title }}
                  </span>

                  <p
                    class="Blog__row--p mt-sm-1 mt-md-2 mt-xl-8"
                    :class="{ changeColor: ns % 2 == 1 }"
                  >
                    {{ newItem.description }}
                  </p>

                  <div class="mt-sm-1 mt-md-2 mt-xl-8">
                    <router-link
                      class="not-text-decoration Blog__row--a"
                      :to="'/post/' + newItem.url"
                      :style="
                        ns % 2 == 1 ? 'border-bottom: 1px solid #ECE8DD;' : ''
                      "
                    >
                      <span
                        class="continuos-texts-base"
                        :class="{ changeColor: ns % 2 == 1 }"
                        >Read more</span
                      >
                    </router-link>
                  </div>
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- **********/termina news********** -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: [],
      heightCarouselNews: 656,
      heightCarouselNewsT: 494,
      carouselNews: 0,
      carouselNewsText: 0,
    };
  },
  beforeCreate() {
    //fetch para las noticias
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/news?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sort: { _o: 1 },
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.news = res.entries));
  },
  mounted() {
    //event onResize
    window.addEventListener("resize", this.onResize, { passive: true });

    //emite el valor para cambiar el valor del logo
    this.$emit("update", 1);
  },
  beforeDestroy() {
    //event onResize
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize: function (e) {
      // heightCarouselNews
      if (window.innerWidth <= 599) this.heightCarouselNews = "100%";
      else if (window.innerWidth >= 600 && window.innerWidth <= 959)
        this.heightCarouselNews = 246;
      else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heightCarouselNews = 328;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.heightCarouselNews = 410;
      else if (window.innerWidth >= 1904) this.heightCarouselNews = 656;

      ////////////////heightCarouselNewsT
      if (window.innerWidth <= 599) this.heightCarouselNewsT = 298;
      else if (window.innerWidth >= 600 && window.innerWidth <= 959)
        this.heightCarouselNewsT = 186;
      else if (window.innerWidth >= 960 && window.innerWidth <= 1263)
        this.heightCarouselNewsT = 247;
      else if (window.innerWidth >= 1264 && window.innerWidth <= 1903)
        this.heightCarouselNewsT = 308;
      else if (window.innerWidth >= 1904) this.heightCarouselNewsT = 494;
    },
  },
};
</script>